<template>
  <span v-html="wrappedHtml"></span>
</template>

<script>
export default {
  name: "HtmlReplace",
  props: {
    html: {
      type: String,
      required: true
    },
  },

  computed: {
    wrappedHtml() {
      return this.html;
    }
  },

  data: () => ({
  }),

  mounted: function () {
    this.addListeners();
  },

  watch: {
  },

  methods: {
    addListeners() {
      console.log('a');
      var elements = document.querySelectorAll('.img-zoom');
      elements.forEach((element) => {
        console.log(element);

        element.addEventListener('click', function (e) {
          const tgt = e.target;
          console.log('tgt');

          if (tgt.classList.contains('img-zoomed'))
            tgt.classList.replace('img-zoomed', 'img-zoom');
          else
            tgt.classList.replace('img-zoom', 'img-zoomed');
        });
      });
    }
  }
};
</script>

<style>
.img-zoomed {
  transform: scale(2);
}
</style>